import React from "react"
import '@aws-amplify/ui-react/styles.css'
import '../style/stylesheet.css'
const Login: React.FC = () => {
    return ( //returns if user not logged in
        <div className="flex-container">
            <div className="flex-item-half">
                <div className="flex-container-inner">
                    <div>
                        <img className="login-logo" src="https://i.postimg.cc/TwgwmbD9/unnamed.png"></img>
                        <h2>Sign in</h2>
                    </div>
                </div>
            </div>
            <div className="flex-item-right">
                <img width="1000px" src={require("../assets/yacht-aerial-foto.jpg")}></img>
            </div>
        </div>
    )
}

export default Login