import { useStore } from "../../models/Rootstore";
import React, { useCallback, useEffect, useState } from 'react';
import '../../style/stylesheetDashboard.css'
import '../../style/stylesheetDiagnose.css'
import '../../style/stylesheet.css'
import { observer } from "mobx-react-lite";
import { Tabs, Button, Progress, DatePicker, Dropdown, Row, Col, Input } from 'antd';
import { CheckOutlined, DownOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import type { MenuProps } from 'antd';

import { useNavigate, useParams } from "react-router-dom";
import EquipmentTable from "../EquipmentTable";
import '@aws-amplify/ui-react/styles.css'

import { Content } from "antd/es/layout/layout";
import ManualEvents from "../ManualEvents";
import Jobs from "../Jobs";

import './Dashboard.css';

const { TabPane } = Tabs;
const Dashboard: React.FC = () => {
    const { yachtname, activeKey } = useParams()
    const [currentYachtPicture, setCurrentYachtPicture] = useState("")
    const [currentBlackBoxId, setCurrentBlackBoxId] = useState("")
    const [ newMessage, replaceNewMessage ] = useState('')
    const [ isEditing, setIsEditing ] = useState(false)

    const navigate = useNavigate()
    const { yachtsList, usersList } = useStore()
    const state = yachtsList.state

    const toggleEditing = useCallback(() => setIsEditing(!isEditing), [ isEditing, setIsEditing ])
    const onNewMessageChange = useCallback(({ target: { value } }: any) => replaceNewMessage(value), [ replaceNewMessage ])

    useEffect(() => {
        if (state === "FETCHED" && currentYachtPicture === "") {
            for (const element of yachtsList.yachts) {
                if (yachtname === element.name) {
                    setCurrentYachtPicture(element.image);
                    setCurrentBlackBoxId(element.blackboxId);
                }
            }
        }
    }, [currentYachtPicture, yachtname, state, yachtsList.yachts])

    const onChange = useCallback((key: string) => {
        navigate(`/dashboard/${yachtname}/${key}`)
    }, [navigate, yachtname])

    let tabbarButtons = {}
    
    const { RangePicker } = DatePicker;
    const [unixTimestampFrom, setUnixTimestampFrom] = useState(Date.now() - 604800000); //calculating unix timestamp for grafana graphs timerange (with useState to update graphs when variable changes)
    const [unixTimestampTo, setUnixTimestampTo] = useState(Date.now());

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates && dates[0] && dates[1]) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            setUnixTimestampFrom(dates[0].unix() * 1000); //convert to milliseconds
            setUnixTimestampTo(dates[1].unix() * 1000);

            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            console.log('Unix From: ', unixTimestampFrom, ', Unix To: ', unixTimestampTo);

        } else {
            console.log('Clear');
        }
    };


    const rangePresets: { //presets the ranges for the datepicker:
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: 'Last Hour', value: [dayjs().add(-1, 'hours'), dayjs()] },
            { label: 'Last 3 Hours', value: [dayjs().add(-3, 'hours'), dayjs()] },
            { label: 'Last 12 Hours', value: [dayjs().add(-12, 'hours'), dayjs()] },
            { label: 'Last Day', value: [dayjs().add(-1, 'd'), dayjs()] },
            { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
            { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
        ];
    //

    //diagnoseTab dropdown menu:    
    const items: MenuProps['items'] = [ //sets items for dropdown 
        { key: 'General', label: 'General' },
        { key: 'Tanklevels', label: 'Tanklevels' },
        { key: 'Propulsion', label: 'Propulsion' },
        { key: 'Electrical', label: 'Electrical' },
        { key: 'Environment', label: 'Environment' }
    ];

    const [panelIdGraph1, setpanelIdGraph1] = useState(6); //sets the Grafana panelIds and updates graphs when dropdown item is clicked
    const [panelIdGraph2, setpanelIdGraph2] = useState(41);
    const [panelIdGraph3, setpanelIdGraph3] = useState(43);
    const [panelIdGraph4, setpanelIdGraph4] = useState(44);
    const [panelIdGraph5, setpanelIdGraph5] = useState(61);
    const [dropdownKey, setKey] = useState('General');

    useEffect(() => {
      switch (dropdownKey) {
        case 'General':
          setpanelIdGraph1(6);
          setpanelIdGraph2(41);
          setpanelIdGraph3(43);
          setpanelIdGraph4(44);
          setpanelIdGraph5(61);
          break;
        case 'Tanklevels':
          setpanelIdGraph1(6);
          setpanelIdGraph2(43);
          setpanelIdGraph3(55);
          setpanelIdGraph4(56);
          setpanelIdGraph5(57);
          break;
        case 'Propulsion':
          setpanelIdGraph1(6);
          setpanelIdGraph2(63);
          setpanelIdGraph3(65);
          setpanelIdGraph4(56);
          setpanelIdGraph5(57);
          break;
      }
    }, [dropdownKey]);

    const handleMenuClick: MenuProps['onClick'] = (e) => {
      setKey(e.key);
    }

    const menuProps = {
      items,
      onClick: handleMenuClick,
    };

    const status = usersList.yachtStatus
    const getLocation = () => {
      if (!status) {
        return {}
      }

      const { latitude, longitude } = status.location
      return { latitude, longitude }
    }

    const getStaticMap = () => {
      if (!status) {
        return ''
      }

      const { latitude, longitude } = getLocation()
      return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&size=600x300&markers=${latitude},${longitude}&key=AIzaSyCWwBdADzxAcDtfayERNHSaSe1VFLZhCvY`
    }
    
    return (
        <Content style={{ marginLeft: 20, marginTop: 20, overflow: 'initial' }} >
            <h2>{yachtname}</h2>

            <Tabs className="tab" defaultActiveKey={activeKey} onChange={onChange} tabBarExtraContent={tabbarButtons}>
              <TabPane tab="Dashboard" key="Dashboard">
                <Row gutter={16}>
                  <Col xs={24} md={8}>
                    <div className='dashboard--yacht-image'>
                      <img alt={`${status.name} yacht`} width="100%" src={status.image} />
                    </div>
                    <div className='dashboard--yacht-stats'>
                      <Row>
                        <Col span={2} />
                        <Col span={4} style={{ textAlign: 'center' }}>
                          <Progress type="circle" width={50} format={() => '8'} percent={80} />
                          <p style={{ padding: '0.5em 0' }}>Drivetrain</p>
                        </Col>

                        <Col span={4} style={{ textAlign: 'center' }}>
                          <Progress type="circle" width={50} format={() => '7'} percent={70} />
                          <p style={{ padding: '0.5em 0' }}>Electrical</p>
                        </Col>

                        <Col span={4} style={{ textAlign: 'center' }}>
                          <Progress type="circle" width={50} format={() => '3'} percent={30} />
                          <p style={{ padding: '0.5em 0' }}>Machinery</p>
                        </Col>

                        <Col span={4} style={{ textAlign: 'center' }}>
                          <Progress type="circle" width={50} format={() => '9'} percent={90} />
                          <p style={{ padding: '0.5em 0' }}>HVAC</p>
                        </Col>

                        <Col span={4} style={{ textAlign: 'center' }}>
                          <Progress type="circle" width={50} format={() => '6'} percent={60} />
                          <p style={{ padding: '0.5em 0' }}>Appliances</p>
                        </Col>

                        <Col span={2} />
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className='dashboard--map-image'>
                      <img alt='' src={getStaticMap()} style={{ width: '100%', height: 'auto' }} />
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className='dashboard--status-message'>
                      <div className='dashboard--status-message--title'>Status message <span onClick={toggleEditing}><EditOutlined /></span></div>
                      {isEditing && (<div className='dashboard--status-message--editor'>
                        <Row>
                          <Col span={22}>
                            <Input size='small' value={newMessage} onChange={onNewMessageChange} placeholder='Status message...' max={100} />
                          </Col>
                          <Col span={2}>
                            <Button type='link' size='small' onClick={toggleEditing}>
                              <SaveOutlined />
                            </Button>
                          </Col>
                        </Row>
                      </div>)}
                      {!isEditing && (<div className='dashboard--status-message--message'><CheckOutlined /> All OK</div>)}
                    </div>

                    <div className='dashboard--metrics'>
                      <Row>
                        <Col span={6}>
                          Battery %
                        </Col>
                        <Col span={18}>
                          <Progress
                            format={() => ((status ? status.metrics.batterySOC : 1) * 100).toFixed() + '%'}
                            percent={(status ? status.metrics.batterySOC : 1) * 100}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col span={6}>
                          Fuel %
                        </Col>
                        <Col span={18}>
                          <Progress
                            format={() => ((status ? status.metrics.fuel : 1) * 100).toFixed() + '%'}
                            percent={(status ? status.metrics.fuel : 1) * 100}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col span={6}>
                          Water %
                        </Col>
                        <Col span={18}>
                          <Progress
                            format={() => ((status ? status.metrics.water : 1) * 100).toFixed() + '%'}
                            percent={(status ? status.metrics.water : 1) * 100}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col span={6}>
                          Range %
                        </Col>
                        <Col span={18}>
                          <Progress
                            format={() => ((status ? status.metrics.range || 1 : 1) * 100).toFixed() + '%'}
                            percent={(status ? status.metrics.range || 1 : 1) * 100}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                
                <Row gutter={16}>
                  <Col xs={24} md={8}>
                    <div className='dashboard--column'>
                      <ManualEvents />
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className='dashboard--column'>
                      <Jobs kind='all' />
                    </div>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tab="Equipment" key="Equipment">
                <EquipmentTable title='Equipment' />
              </TabPane>

              <TabPane tab="Planned Maintenance" key="Planned Maintenance">
                <EquipmentTable title='Planned Maintenance' />
              </TabPane>

              <TabPane tab="Diagnose" key="Diagnose">
                <Dropdown menu={menuProps}>
                  <Button>
                    {dropdownKey} <DownOutlined />
                  </Button>
                </Dropdown>

                <RangePicker
                  presets={rangePresets}
                  format="YYYY/MM/DD HH:mm:ss"
                  onChange={onRangeChange}
                  defaultValue={[dayjs(unixTimestampFrom), dayjs(unixTimestampTo)]}
                />
                <div className="gridcontainer-diagnose">
                  <div className="griditem-diagnose1">
                    <iframe className="graph" src={"https://grafana-staging.marinminds.cloud/d-solo/_HWV4ryGz/main-view?orgId=1&from=" + unixTimestampFrom + "&to=" + unixTimestampTo + "&var-Yacht=" + currentBlackBoxId + "&var-Fill=null&panelId=" + panelIdGraph1} width="100%" height="300px" style={{ display: "block", margin: "0 auto" }} />
                  </div>
                  <div className="griditem-diagnose2">
                    <iframe className="graph" src={"https://grafana-staging.marinminds.cloud/d-solo/_HWV4ryGz/main-view?orgId=1&from=" + unixTimestampFrom + "&to=" + unixTimestampTo + "&var-Yacht=" + currentBlackBoxId + "&var-Fill=null&panelId=31"} width="100%" height="300px" style={{ display: "block", margin: "0 auto" }} />
                  </div>
                  <div className="griditem-diagnose3">
                    <iframe className="graph" src={"https://grafana-staging.marinminds.cloud/d-solo/_HWV4ryGz/main-view?orgId=1&from=" + unixTimestampFrom + "&to=" + unixTimestampTo + "&var-Yacht=" + currentBlackBoxId + "&var-Fill=null&panelId=" + panelIdGraph2} width="100%" height="300px" style={{ display: "block", margin: "0 auto" }} />
                  </div>
                  <div className="griditem-diagnose4">
                    <iframe className="graph" src={"https://grafana-staging.marinminds.cloud/d-solo/_HWV4ryGz/main-view?orgId=1&from=" + unixTimestampFrom + "&to=" + unixTimestampTo + "&var-Yacht=" + currentBlackBoxId + "&var-Fill=null&panelId=" + panelIdGraph3} width="100%" height="300px" style={{ display: "block", margin: "0 auto" }} />
                  </div>
                  <div className="griditem-diagnose5">
                    <iframe className="graph" src={"https://grafana-staging.marinminds.cloud/d-solo/_HWV4ryGz/main-view?orgId=1&from=" + unixTimestampFrom + "&to=" + unixTimestampTo + "&var-Yacht=" + currentBlackBoxId + "&var-Fill=null&panelId=" + panelIdGraph4} width="100%" height="300px" style={{ display: "block", margin: "0 auto" }} />
                  </div>
                  <div className="griditem-diagnose6">
                    <iframe className="graph" src={"https://grafana-staging.marinminds.cloud/d-solo/_HWV4ryGz/main-view?orgId=1&from=" + unixTimestampFrom + "&to=" + unixTimestampTo + "&var-Yacht=" + currentBlackBoxId + "&var-Fill=null&panelId=" + panelIdGraph5} width="100%" height="300px" style={{ display: "block", margin: "0 auto" }} />
                  </div>
                </div>
              </TabPane>
            </Tabs>
        </Content>
    )
}

export default observer(Dashboard)