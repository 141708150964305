import { flow, Instance, types } from "mobx-state-tree";
import { API } from "../lib/api";
import { NotificationInput } from "../lib/types";

const maybe = (type: any) => types.maybe(types.maybeNull(type));

export const Job: any = types
  .model('Job', {
    id: types.string,
    createdAt: types.number,
    updatedAt: types.number,
    title: types.string,
    body: maybe(types.string),
    level: maybe(types.string),
    seenBy: maybe(types.array(types.string)),
    sentBy: maybe(types.string),
    subtitle: maybe(types.string),
    icon: maybe(types.string),
    isJob: types.optional(maybe(types.boolean), false),
    jobStatus: types.optional(maybe(types.enumeration([ 'PENDING', 'REJECTED', 'ACCEPTED', 'IN_PROGRESS', 'DONE', 'STUCK', 'FAILED' ])), 'PENDING'),
    notes: types.optional(maybe(types.string), ''),
    parent: maybe(types.string),
  })

export const Jobs = types
  .model("Jobs", {
    state: types.optional(types.enumeration(['PENDING', 'FETCHING', 'FETCHED', 'ERRORED']), 'PENDING'),
    jobs: types.array(Job),
  })
  .actions((self) => {
    const actions: any = {
      replace (events: any[]) {
        self.jobs.replace([])
        self.jobs.replace(events)
      },

      fetch: flow(function* fetch() {
        if (self.state === 'FETCHING') {
          return
        }

        try {
          self.state = 'FETCHING'
          const { getNotifications } = yield API.notifications.list()
          console.log(`[getNotifications]`, getNotifications)
          const items = getNotifications.map((item: any) => Job.create(item))
          actions.replace(items)
          self.state = 'FETCHED'
        } catch (err) {
          self.state = 'ERRORED'
          console.error('Failed to fetch', err)
        }
      }),

      create: flow(function* create(data: NotificationInput) {
        try {
          yield API.notifications.create(data);
          yield actions.fetch();
        } catch (err) {
          self.state = 'ERRORED'
          console.error('Failed to create', err)
        }
      }),

      update: flow(function* update(id, update: NotificationInput) {
        try {
          yield API.notifications.update(id, update);
          yield actions.fetch();
        } catch (err) {
          self.state = 'ERRORED'
          console.error('Failed to update', err)
        }
      }),
      
      destroy: flow(function* destroy(id) {
        try {
          yield API.notifications.destroy(id);
          yield actions.fetch();
        } catch (err) {
          self.state = 'ERRORED'
          console.error('Failed to delete', err)
        }
      }),
    }
    return actions
  })

export interface JobModel extends Instance<typeof Job> {}
export interface JobsModel extends Instance<typeof Jobs> {}
