import React from 'react';
import Router from './Router';
import { StoreProvider, RootStore } from "../models/Rootstore";
import { observer } from 'mobx-react-lite';

const rootStore = RootStore.create();

const SetupProviders: React.FC<unknown> = () => {
  return (
    <StoreProvider value={rootStore}>
      <Router />
    </StoreProvider>
  );
};

export default observer(SetupProviders)