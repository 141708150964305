import { useStore } from "../models/Rootstore"
import { Table, Button, Space, Form, Typography, Popconfirm, Input, Select, Tag } from 'antd'
import { observer } from "mobx-react-lite"
import { DataTypeUser, EditableCellProps } from "../@types/DataTypes"
import { API_ENDPOINT, fetchJson } from "../lib/fetch"
import React, { useCallback, useState } from 'react'
const { Option } = Select;

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    children,
    ...restProps
}) => {
    const { yachtsList } = useStore()
    let inputNode = undefined
    if (inputType === "input") {
        inputNode = <Input />;
    }
    else if (title === "Yachts") {
        inputNode =
            <Select mode="multiple">
                {yachtsList.yachts.map((item: any, index: any) => (
                    <Option value={item.name} key={item[index]}>
                        {item.name}
                    </Option>
                ))}
            </Select>
    }
    else if (title === "Role") {
        inputNode =
            <Select>
                <Option value="manager">manager</Option>
                <Option value="yo">yo</Option>
                <Option value="sym">sym</Option>
            </Select>
    }
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Please Input ${title}!` }]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    )
}

const UserTable: React.FC = () => {
    const { usersList } = useStore()
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record: any) => record.key === editingKey;

    const edit = useCallback((record: any) => {
        form.setFieldsValue({
            last_name: '',
            first_name: '',
            emailaddress: '',
            phonenumber: '',
            role: '',
            status: '',
            ...record,
        });
        setEditingKey(record.key)
    }, [form])

    const cancelEditing = useCallback(() => {
        setEditingKey('');
    }, [])

    const save = useCallback(async (id: string) => {
        try {
            const row = await form.validateFields();
            row.yachts = []
            const yachts = await fetchJson(`${API_ENDPOINT}/api/compat/yacht`)
            for (let i = 0; i < yachts.length; i++) {
                for (let j = 0; j < row.yachtUser.length; j++) {
                    if (row.yachtUser[j] === yachts[i].name) {
                        row.yachts[i].push(yachts[i])
                    }
                }
            }
            usersList.updateUser(id, row)
            setEditingKey('');
        }
        catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }, [form, usersList])
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            editable: true,
            type: 'input'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            editable: true,
            type: 'input'
        },
        {
            title: 'Phone number',
            dataIndex: 'phone_number',
            key: 'phone_number',
            editable: true,
            type: 'input'
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            editable: true,
            type: 'dropdown'
        },
        {
            title: 'Yachts',
            dataIndex: 'yachtsUser',
            render: (theYachtTags: Array<string>) => theYachtTags?.map((item: any, index: any) => (
                <Tag key={item[index]}>{item}</Tag>
            )),
            key: 'yachts',
            editable: true,
            type: 'dropdown'
        },
        {
            title: "Delete",
            render: (record: DataTypeUser) => (
                <Space size="middle">
                    <Button onClick={() => usersList.destroyUser(record.id)}>Delete</Button>
                </Space>
            )
        },
        {
            title: "Edit",
            render: (_: any, record: DataTypeUser) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancelEditing}>
                            Cancel
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link
                        disabled={editingKey !== ""}
                        onClick={() => edit(record)}
                    >
                        Edit
                    </Typography.Link>
                )
            }
        }
    ]


    if (usersList.state !== 'FETCHED') {
        return (
            <section>
                <h1>Loading....</h1>
            </section>
        )
    }

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: DataTypeUser) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                inputType: col.type,
            })
        }
    })
    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                columns={mergedColumns}
                dataSource={usersList.users}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancelEditing,
                }}
            />
        </Form>
    )
}

export default observer(UserTable)
