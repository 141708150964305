
import { types, Instance } from 'mobx-state-tree'
import React, { createContext } from 'react';
import { UsersList } from './Users'
import { YachtsList } from './Yachts'
import { EquipmentList } from './Equipment';
import { EventList } from './Events';
import { Jobs } from './Jobs';

export const RootStore = types
    .model({
        usersList: types.optional(UsersList, {
            users: []
        }),
        yachtsList: types.optional(YachtsList, {
            yachts: []
        }),
        equipmentList: types.optional(EquipmentList, {
            equipment: [],
        }),
        eventList: types.optional(EventList, {
            events: [],
        }),
        jobs: types.optional(Jobs, {
            jobs: [],
        }),
        activeKey: types.optional(types.string, "Users"),
        userFormVisible: types.optional(types.boolean, false),
        yachtFormVisible: types.optional(types.boolean, false),
        componentFormVisible: types.optional(types.boolean, false),
        equipmentFormVisible: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        afterCreate() {
            self.usersList.loadUsers()
            self.yachtsList.loadYachts()
            self.equipmentList.loadEquipment()
            self.eventList.loadEvents()
            self.jobs.fetch()
        },
        setActiveKey(key: string) {
            self.activeKey = key;
        },
        setuserFormVisible(bool: boolean) {
            self.userFormVisible = bool;
        },
        setyachtFormVisible(bool: boolean) {
            self.yachtFormVisible = bool;
        },
        setequipmentFormVisible(bool: boolean) {
            self.equipmentFormVisible = bool
        },
        setcomponentFormVisible(bool: boolean) {
            self.componentFormVisible = bool
        }
    }))

const RootStoreContext = createContext<null | Instance<typeof RootStore>>(null);
export const StoreProvider = RootStoreContext.Provider;

export function useStore() {
    const store = React.useContext(RootStoreContext);
    if (store === null) {
        throw new Error('Store cannot be null, please add a context provider');
    }
    return store;
}
