import JSSHA from 'jssha'

export default class Uploader {
    private file: any = null
    private project: string = ''
    private action: string = 'https://decipher.digital/media/api/v1/file'
    private userId: string = ''
    private data: any = {}
    private headers: any = {}

    public constructor(file: any, user: any) {
        this.file = file
        this.project = (user.project && user.project === 'object') ? user.project.id : user.project
        this.userId = user.id
    }

    public upload() {
        const file = this.file
        const form = new (window as any).FormData()
        const xhr = new (window as any).XMLHttpRequest()
        const filename = encodeURIComponent(file.name)

        try {
            form.append('Content-Type', file.type || 'application/octet-stream')
            form.append('file', file)

            if (typeof this.data === 'object' && this.data !== null) {
                for (const key in this.data) {
                    if (this.data.hasOwnProperty(key)) {
                        form.append(key, this.data[key])
                    }
                }
            }
        } catch (err) {
            return Promise.reject(err)
        }

        return new Promise((resolve, reject) => {
            xhr.onreadystatechange = () => {
                if (xhr.readyState < 4) {
                    return
                }

                if (xhr.status < 400) {
                    let result

                    try {
                        result = JSON.parse(xhr.responseText)
                    } catch (e) {
                        result = xhr.responseText
                    }

                    result.url = `${this.action}/download/${result.id}`
                    resolve(result)
                } else {
                    let result: any
                    let error: any

                    try {
                        result = JSON.parse(xhr.responseText)
                        error = new Error(result.error)
                    } catch (e) {
                        result = xhr.responseText
                        error = new Error(result)
                    }

                    error.status = xhr.status
                    error.statusText = xhr.statusText
                    reject(error)
                }
            }

            xhr.onerror = () => {
                let result: any
                let error: any

                try {
                    result = JSON.parse(xhr.responseText)
                    error = new Error(result.error)
                } catch (e) {
                    result = xhr.responseText
                    error = new Error(result)
                }

                error.status = xhr.status
                error.statusText = xhr.statusText
                reject(error)
            }

            const sig = this.signature()
            xhr.open('POST', `${this.action}?date=${sig.date}&signature=${sig.signature}&project=${sig.project}&owner=${this.userId}&name=${filename}`, true)

            if (this.headers) {
                for (const header in this.headers) {
                    if (this.headers.hasOwnProperty(header)) {
                        xhr.setRequestHeader(header, this.headers[header])
                    }
                }
            }

            xhr.send(form)
        })
    }

    public getPathName(uri: string) {
        const a = document.createElement('a')
        a.href = uri
        return a.pathname
    }

    public signature() {
        const sharedKey = '54yT\'JE2HA6zzay9ImLboM12YxL{Y6'
        const hmacKey = 'DeD5;h3NH02P3W[16z9O6%&33eQ~#}'
        const date = new Date().toISOString()
        let plaintext = ''

        plaintext += 'POST\n'
        plaintext += this.getPathName(this.action) + '\n'
        plaintext += date + '\n'
        plaintext += sharedKey + '\n'
        plaintext += this.project

        const shaObj = new JSSHA('SHA-256', 'TEXT')
        shaObj.setHMACKey(hmacKey, 'TEXT')
        shaObj.update(plaintext)

        return {
            date,
            signature: shaObj.getHMAC('HEX'),
            project: this.project
        }
    }
}
