import { flow, Instance, types } from "mobx-state-tree";
import { API, OptionalEmptyString } from "../lib/api";
import { EquipmentInput } from "../lib/types";

export const Component: any = types
  .model("Component", {
    key: types.number,
    equipmentid: types.string,
    name: types.string,
    supplier: types.string,
    make: types.string,
    model: types.string,
    partnumber: types.string,
    usage_hrs: types.number
  })

export const Equipment: any = types
  .model("Equipment", {
    id: types.string,
    createdAt: types.number,
    updatedAt: types.number,
    name: types.string,
    description: OptionalEmptyString,
    isGroup: types.optional(types.boolean, false),
    isTask: types.optional(types.boolean, false),
    attachments: types.optional(types.array(types.string), []),
    make: OptionalEmptyString,
    model: OptionalEmptyString,
    location: OptionalEmptyString,
    serviceSupplier: OptionalEmptyString,
    partNumber: OptionalEmptyString,
    sku: OptionalEmptyString,
    taskType: OptionalEmptyString,
    taskStatus: OptionalEmptyString,
    taskInterval: OptionalEmptyString,
    parent: types.maybe(types.maybeNull(types.string)),
  })

export const EquipmentList = types
  .model("Equipmentlist", {
    state: types.optional(types.enumeration(['PENDING', 'FETCHING', 'FETCHED', 'ERRORED']), 'PENDING'),
    equipment: types.array(Equipment),
  })
  .actions((self) => {
    const actions: any = {
      setEquipment(equipment: any[]) {
        // console.log(`[setEquipment]`, JSON.parse(JSON.stringify(equipment)))
        self.equipment.replace([])
        self.equipment.replace(equipment)
      },

      loadEquipment: flow(function* loadEquipment() {
        if (self.state === 'FETCHING') {
          return
        }

        try {
          self.state = 'FETCHING'
          const { listEquipment } = yield API.equipment.list()
          console.log('[listEquipment]', listEquipment)
          const items = listEquipment.map((item: any) => Equipment.create(item))
          actions.setEquipment(items)
          self.state = 'FETCHED'
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to load equipment ", err)
        }
      }),

      updateEquipment: flow(function* updateEquipment(id: string, update: EquipmentInput) {
        try {
          yield API.equipment.update(id, update);
          yield actions.loadEquipment();
        } catch (err) {
          console.error("Failed to update equipment ", err)
        }
      }),

      createEquipment: flow(function* createEquipment(data: EquipmentInput) {
        try {
          yield API.equipment.create(data);
          yield actions.loadEquipment();
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to create equipment ", err)
        }
      }),
      
      destroyEquipment: flow(function* destroyEquipment(id) {
        try {
          yield API.equipment.destroy(id);
          yield actions.loadEquipment();
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to delete equipment", err)
        }
      }),
    }
    return actions
  })

export interface EquipmentModel extends Instance<typeof Equipment> {}
export interface EquipmentListModel extends Instance<typeof EquipmentList> {}
