import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'
import { Row, Col, Input, Select, Button } from 'antd'
import { ExclamationCircleOutlined, InfoCircleOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons'
import { useStore } from '../../models/Rootstore'

import './AddManualEvent.css'

export const AddManualEvent: React.FC = observer(() => {
  const { eventList } = useStore()

  const [ name, replaceName ] = useState('')
  const [ icon, replaceIcon ] = useState('info')
  const [ level, replaceLevel ] = useState('normal')
  const [ isLoading, setIsLoading ] = useState(false)

  const handleNameChange = useCallback(({ target: { value } }: any) => replaceName(value), [ replaceName ])
  const handleIconChange = useCallback((value: string) => {
    if (value === 'info') {
      replaceLevel('normal')
      replaceIcon('info')
    }

    if (value === 'warning') {
      replaceLevel('warning')
      replaceIcon('warning')
    }

    if (value === 'danger') {
      replaceLevel('danger')
      replaceIcon('danger')
    }
  }, [
    replaceIcon,
    replaceLevel,
  ])

  const clear = useCallback(() => {
    replaceLevel('normal')
    replaceIcon('info')
    replaceName('')
    setIsLoading(false)
  }, [
    replaceLevel,
    replaceIcon,
    replaceName,
    setIsLoading,
  ])

  const add = useCallback(() => {
    if (name.trim() === '') {
      return
    }
    
    const input = {
      name,
      icon,
      level,
    }

    setIsLoading(true)
    eventList.createEvent(input)
    clear()
  }, [
    name,
    icon,
    level,
    eventList,
    setIsLoading,
    clear,
  ])

  return (
    <div className='add-manual-event'>
      <div className='add-manual-event--outer'>
        <div className='add-manual-event--inner'>
          <Row>
            <Col span={6}>
              <Select
                value={icon}
                disabled={isLoading}
                onChange={handleIconChange}
                size='large'
                style={{ width: 'calc(100% - 0.25em)' }}
                options={[
                  { value: 'info', label: <InfoCircleOutlined /> },
                  { value: 'warning', label: <WarningOutlined /> },
                  { value: 'danger', label: <ExclamationCircleOutlined /> },
                ]}
              />
            </Col>
            <Col span={18}>
              <Input placeholder='Event...' size='large' disabled={isLoading} value={name} onChange={handleNameChange} />
            </Col>
          </Row>
          <Row style={{ marginTop: '0.5em' }}>
            <Col span={6} style={{ paddingRight: '0.5em' }}>
              <Button size='small' block onClick={clear} disabled={isLoading}>
                <StopOutlined />
              </Button>
            </Col>
            <Col span={18}>
              <Button type='primary' size='small' block onClick={add} disabled={name.trim() === '' || isLoading}>
                Add
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
})
