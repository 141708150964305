import axios from 'axios';
import { API_ENDPOINT } from './fetch';

export const editYacht = async (id: any, updateData: any) => {
    await axios
        .post(`${API_ENDPOINT}/api/compat/yacht/update/` + id, updateData)
        .then((res: any) => console.log(res.data))
}
export const addYacht = async (data: any) => {
    await axios
        .post(`${API_ENDPOINT}/api/compat/yacht/add`, data)
        .then((res: any) => console.log(res.data))
}
export const deleteYacht = async (id: string) => {
    await axios
        .delete(`${API_ENDPOINT}/api/compat/yacht/${id}`)
        .then((res: any) => console.log(res.data))
}
