import { gql } from 'graphql-request'
import { fetchGQL, fetchJson } from './fetch'
import { EquipmentInput, EventInput, NotificationInput, YachtStatus } from './types'
import { types } from "mobx-state-tree";

export const OptionalEmptyString = types.optional(types.string, '');

export const API = {
  yachts: {
    async status (identity: string): Promise<YachtStatus> {
      const result = await fetchJson(`/api/v1/state?identity=${identity}&fields=image,metrics,status`)
      const { image, location, metrics, status, name } = result
      return { image, location, metrics, status, name }
    }
  },

  notifications: {
    async list () {
      const query = gql`
        query {
          getNotifications(filter: "{}") {
            id
            title
            body
            level
            seenBy
            sentBy
            sound
            createdAt
            updatedAt
            subtitle
            icon
            isJob
            jobStatus
            notes
            parent
          }
        }
      `
      return fetchGQL(query)
    },
    async show (id: string) {
      const query = gql`
        query {
          getNotification(id: "${id}") {
            id
            title
            body
            level
            seenBy
            sentBy
            sound
            createdAt
            updatedAt
            subtitle
            icon
            isJob
            jobStatus
            notes
            parent
          }
        }
      `
      return fetchGQL(query)
    },
    async destroy (id: string) {
      const query = gql`
        mutation {
          deleteNotification(id: "${id}") { id }
        }
      `
      return fetchGQL(query)
    },
    async update (id: string, data: NotificationInput) {
      const stringFields = Object
        .keys(data)
        .filter((key: string) => (typeof (data as any)[key] === 'string'))
        .map((key: string) => `${key}: "${(data as any)[key]}",\n`)

      const otherFields = Object
        .keys(data)
        .filter((key: string) => (typeof (data as any)[key] !== 'string'))
        .map((key: string) => `${key}: ${(data as any)[key]},\n`)

      const query = gql`
        mutation {
          updateNotification(id: "${id}", data: {
            ${stringFields}
            ${otherFields}
          }) { id }
        }
      `
      return fetchGQL(query)
    },
    async create (data: NotificationInput) {
      const stringFields = Object
        .keys(data)
        .filter((key: string) => (typeof (data as any)[key] === 'string'))
        .map((key: string) => `${key}: "${(data as any)[key]}",\n`)

      const otherFields = Object
        .keys(data)
        .filter((key: string) => (typeof (data as any)[key] !== 'string'))
        .map((key: string) => `${key}: ${(data as any)[key]},\n`)

      const query = gql`
        mutation {
          createNotification(data: {
            ${stringFields}
            ${otherFields}
          }) { id }
        }
      `
      return fetchGQL(query)
    },
  },

  events: {
    async list () {
      const query = gql`
        query {
          listEvents(filter: "{}") {
            id
            createdAt
            updatedAt
            name
            icon
            level
          }
        }
      `
      return fetchGQL(query)
    },
    async show (id: string) {
      const query = gql`
        query {
          getEvent(id: "${id}") {
            id
            createdAt
            updatedAt
            name
            icon
            level
          }
        }
      `
      return fetchGQL(query)
    },
    async destroy (id: string) {
      const query = gql`
        mutation {
          deleteEvent(id: "${id}") { id }
        }
      `
      return fetchGQL(query)
    },
    async create (data: EventInput) {
      const query = gql`
        mutation {
          createEvent(data: {
            name: "${data.name}",
            icon: "${data.icon || 'info'}",
            level: "${data.level || 'normal'}",
          }) { id }
        }
      `
      return fetchGQL(query)
    },
  },
  equipment: {
    async list () {
      const query = gql`
        query {
          listEquipment(filter: "{}") {
            id
            createdAt
            updatedAt
            name
            description
            parent
            isGroup
            isTask
            attachments
            make
            model
            location
            serviceSupplier
            partNumber
            sku
            taskType
            taskStatus
            taskInterval
          }
        }
      `
      return fetchGQL(query)
    },

    async show (id: string) {
      const query = gql`
        query {
          getEquipment(id: "${id}") {
            id
            createdAt
            updatedAt
            name
            description
            parent { id }
            isGroup
            isTask
            attachments
            children { id }
            make
            model
            location
            serviceSupplier
            partNumber
            sku
            taskType
            taskStatus
            taskInterval
          }
        }
      `
      return fetchGQL(query)
    },

    async destroy (id: string) {
      const query = gql`
        mutation {
          deleteEquipment(id: "${id}") { id }
        }
      `
      return fetchGQL(query)
    },
 
    async update (id: string, data: EquipmentInput) {
      const query = gql`
        mutation {
          updateEquipment(id: "${id}", data: {
            name: "${data.name}",
            description: "${data.description}",
            isGroup: ${data.isGroup},
            isTask: ${data.isTask},
            attachments: ${data.attachments},
            make: "${data.make}",
            model: "${data.model}",
            location: "${data.location}",
            serviceSupplier: "${data.serviceSupplier}",
            partNumber: "${data.partNumber}",
            sku: "${data.sku}"
          }) { id }
        }
      `
      return fetchGQL(query)
    },
    
    async create (data: EquipmentInput) {
      const query = gql`
        mutation {
          createEquipment(data: {
            name: "${data.name}",
            description: "${data.description || ''}",
            isGroup: ${data.isGroup || false},
            isTask: ${data.isTask || false},
            make: "${data.make || ''}",
            model: "${data.model || ''}",
            parent: "${data.parent || ''}",
            location: "${data.location || ''}",
            serviceSupplier: "${data.serviceSupplier || ''}",
            partNumber: "${data.partNumber || ''}",
            sku: "${data.sku || ''}"
          }) { id }
        }
      `
      return fetchGQL(query)
    },
  },
}

export default API