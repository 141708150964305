import ReactDOM from "react-dom/client";
import SetupProviders from "./components/SetupProviders";
import Amplify from "@aws-amplify/core";
import config from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import { BrowserRouter } from "react-router-dom"
import { I18n } from 'aws-amplify';
import { translations } from "@aws-amplify/ui-react";
Amplify.configure(config);

I18n.putVocabularies(translations);
I18n.setLanguage('en');

I18n.putVocabulariesForLanguage('en', {
    'Sign in': 'Login',
    'Email': 'Email address',
    'Password': 'Password',
    'Forgot your password?': 'Forgot password?'
});

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <BrowserRouter>
        <Authenticator hideSignUp={true}>
            <SetupProviders />
        </Authenticator>
    </BrowserRouter>
)
