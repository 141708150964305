import { useStore } from "../models/Rootstore";
import { Button, Form, Input, Popconfirm, Space, Table, Typography } from 'antd'
import { DataTypeYacht, EditableCellProps } from "../@types/DataTypes";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from 'react';

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    children,
    ...restProps
}) => {
    const inputNode = <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Please Input ${title}!` }]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    )
}

const YachtTable: React.FC = () => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const { yachtsList } = useStore();

    const isEditing = (record: any) => record.key === editingKey;

    const edit = useCallback((record: any) => {
        form.setFieldsValue({
            name: '',
            blackbox_id: '',
            ...record,
        })
        setEditingKey(record.key)
    }, [form])

    const cancelEditing = useCallback(() => {
        setEditingKey('');
    }, [])

    const save = useCallback(async (id: string) => {
        try {
            const row = await form.validateFields();
            yachtsList.updateYacht(id, row)
            setEditingKey('')
        }
        catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }, [form, yachtsList])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            editable: true
        },
        {
            title: 'Blackbox ID',
            dataIndex: 'blackboxId',
            key: 'blackboxId',
            editable: true
        },
        {
            title: 'Yacht foto',
            dataIndex: 'image',
            render: (theYachtPhoto: string | undefined) => <img className="tableImage" alt={theYachtPhoto} src={theYachtPhoto} />,
            key: 'image',
            editable: true
        },
        {
            title: "Delete",
            render: (record: DataTypeYacht) => (
                <Space size="middle">
                    <Button onClick={() => yachtsList.destroyYacht(record.id)}>Delete</Button>
                </Space>
            )
        },
        {
            title: "Edit",
            render: (_: any, record: DataTypeYacht) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancelEditing}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link
                        disabled={editingKey !== ""}
                        onClick={() => edit(record)}
                    >
                        Edit
                    </Typography.Link>
                )
            }
        }
    ]
    if (yachtsList.state !== 'FETCHED') {
        return (
            <section>
                <h1>Loading....</h1>
            </section>
        );
    }

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: DataTypeYacht) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            })
        }
    })
    return (
        <Form form={form} component={false}>
            <Table className="table"
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                columns={mergedColumns}
                dataSource={yachtsList.yachts}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancelEditing,
                }}
            />
        </Form>
    )
}

export default observer(YachtTable)
