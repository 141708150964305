import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'
import { useStore } from '../../models/Rootstore'
import { Row, Col, Modal, Button, Input } from 'antd'
import { CaretDownOutlined, CaretUpOutlined, CheckCircleOutlined, CloseOutlined, DeleteOutlined, FileDoneOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'

import './Jobs.css'
import { JobModel } from '../../models/Jobs'
import { clone } from '../../lib/fetch'
import moment from 'moment'
import { NotificationInput } from '../../lib/types'
import { getSnapshot } from 'mobx-state-tree'
import { useNavigation } from 'react-router-dom'

export const getOffset = (createdAt: number) => {
  const now = Date.now()
  const secs = (now - createdAt) / 1000
  
  if (secs <= 60) {
    return `+${Math.round(secs)}s`
  }

  const mins = secs / 60;

  if (mins < 60) {
    return `+${Math.round(mins)}m`
  }

  return moment(createdAt).format('DD/MM')
}

export const CreateJob: React.FC<{ parent?: string }> = observer(({ parent }) => {
  const { jobs, usersList, yachtsList } = useStore();
  const [ title, replaceTitle ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);
  const { yachtStatus } = usersList

  // @FIXME: HACK. This fails if 2 yachts have the same name. Implemented because Patrick's code doesn't implement a machanism to get the yacht for current user, or the yacht selected
  const yacht = yachtStatus && Array.isArray(yachtsList.yachts) ? yachtsList.yachts.find((yacht: any) => (yacht.name === yachtStatus.name)) : undefined
  const handleTitleChange = useCallback(({ target: { value } }: any) => replaceTitle(value), [ replaceTitle ])
  const createJob = useCallback(() => {
    if (title.trim() === '') {
      return
    }

    setIsLoading(true)

    const input: NotificationInput = {
      title,
      body: title,
      yacht: yacht ? yacht.id : '',
    }

    if (typeof parent === 'string' && parent.trim() !== '') {
      input.parent = parent
    }

    jobs.create(input)

    setTimeout(() => {
      setIsLoading(false)
      replaceTitle('')
    }, 300)
  }, [ title, jobs, parent, yacht ])

  return (
    <section className='create-job'>
      <div className='create-job--outer'>
        <div className='create-job--inner'>
          <Row>
            <Col span={2} style={{ textAlign: 'center' }}>
              {parent ? <CheckCircleOutlined /> : <FileDoneOutlined />}
            </Col>
            <Col span={20}>
              <Input disabled={isLoading} value={title} onChange={handleTitleChange} size='small' placeholder='Job description...' />
            </Col>
            <Col span={2} style={{ textAlign: 'right' }}>
              <Button type='link' size='small' disabled={isLoading || title.trim() === ''} onClick={createJob}>
                <SaveOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  )
})
export interface JobProps {
  job: JobModel,
  destroy: (id: string) => () => any,
  isChild?: boolean, 
}

export const Job: React.FC<JobProps> = ({ job, destroy, isChild }) => {
  const [ isEditing, setEditing ] = useState(false)
  const [ isCollapsed, setIsCollapsed ] = useState(true)
  const toggleCollapsed = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed, setIsCollapsed])
  const toggleEditing = useCallback(() => setEditing(!isEditing), [isEditing, setEditing])
  const { title, createdAt } = job
  const children = isChild ? [] : Array.isArray(job.children) ? job.children : []

  return (
    <section className='jobs--job'>
      <div className='jobs--job--inner'>
        <Row>
          <Col span={2}>{isChild ? <CheckCircleOutlined /> : <FileDoneOutlined />}</Col>
          <Col span={14}>{title}</Col>
          <Col span={isChild ? 6 : 4} style={{ textAlign: 'right' }}>
            {getOffset(createdAt)}
          </Col>
          <Col span={isChild ? 2 : 4}>
            <div className='jobs--job--icons'>
              <Button size='small' type='link' style={{ color: 'red' }} onClick={destroy(job.id)}><DeleteOutlined /></Button>
              {!isChild && (<Button size='small' type='link' onClick={toggleCollapsed} style={{ color: 'black' }}>
                {isCollapsed && <CaretDownOutlined />}
                {!isCollapsed && <CaretUpOutlined />}
              </Button>)}
            </div>
          </Col>
        </Row>
      </div>

      {!isCollapsed && (
        <div className='jobs--job--children'>
          {children.map((job: JobModel) => (
            <Job
              key={job.id}
              job={job}
              isChild={true}
              destroy={destroy}
            />
          ))}
          {isEditing && <CreateJob parent={job.id} />}
          <div className='jobs--job--add'>
            <Button type='link' size='small' onClick={toggleEditing}>
              {isEditing && (<><CloseOutlined /> Cancel</>)}
              {!isEditing && (<><PlusOutlined /> Add subtask...</>)}
            </Button>
          </div>
        </div>
      )}
    </section>
  )
}

export const Jobs: React.FC<{ kind: 'active'|'pending'|'all' }> = observer(({ kind }) => {
  const [ isEditing, setEditing ] = useState(false)
  const store = useStore()
  const { jobs } = store.jobs;

  console.log(getSnapshot(store))

  const toggleAddJob = useCallback(() => setEditing(!isEditing), [ isEditing, setEditing ])
  const destroyJob = useCallback((id: string) => () => Modal.confirm({
    title: 'Weet je het zeker?',
    content: 'Deze actie kan niet ongedaan gemaakt worden',
    onCancel: () => false,
    onOk: () => {
      store.jobs.destroy(id)
      return false
    }
  }), [ store.jobs ])

  const activeKinds = [ 'ACCEPTED', 'IN_PROGRESS', 'STUCK' ]
  const sorter = (a: JobModel, b: JobModel) => (b.createdAt - a.createdAt)

  const children = clone(jobs.slice())
    .sort(sorter)
    .filter((item: JobModel) => (kind === 'all' ? true : kind === 'active' ? activeKinds.includes(item.jobStatus) : item.jobStatus === 'PENDING'))
    .filter((item: JobModel) => (typeof item.parent === 'string' && item.parent.trim() !== ''))

  const list = clone(jobs.slice())
    .sort(sorter)
    .filter((item: JobModel) => (kind === 'all' ? true : kind === 'active' ? activeKinds.includes(item.jobStatus) : item.jobStatus === 'PENDING'))
    .filter((item: JobModel) => (typeof item.parent !== 'string' || item.parent.trim() === ''))
    .map((item: JobModel) => ({
      ...item,
      children: children.filter((child: JobModel) => (child.parent === item.id))
    }))

  return (
    <section className='jobs'>
      <div className='jobs--outer'>
        <div className='jobs--inner'>
          <Row>
            <Col span={20}>
              <h1>{kind === 'all' ? 'Jobs' : kind === 'pending' ? 'Pending Jobs' : 'Active Jobs'}</h1>
            </Col>
            <Col span={4}>
              <div className='jobs--add-job' onClick={toggleAddJob}>
                {isEditing ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
              </div>
            </Col>
          </Row>

          {isEditing && <CreateJob />}
          
          <div className='jobs--list'>
            {list.slice(0, 10).sort(sorter).map((item: JobModel) => <Job key={item.id} job={item} destroy={destroyJob} />)}
          </div>
        </div>
      </div>
    </section>
  )
})

export default(Jobs)
