import { Form, Input, Modal, Upload, Button } from 'antd';
import { useStore } from '../models/Rootstore';
import { observer } from 'mobx-react-lite';
import Uploader from './Uploader'
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';

const YachtForm: React.FC = () => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([] as any)
    const [uploading, setUploading] = useState(false)
    const { setyachtFormVisible, yachtFormVisible, yachtsList } = useStore();

    const getBase64 = useCallback((file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result as string)
            reader.onerror = (error) => reject(error)
        })
    }, [])

    const upload = useCallback(async (files: File[]) => {
        try {
            let file: File | File[] = files

            if (file.length === 0) {
                return
            }

            file = file[0]

            const uploader = new Uploader(file, {})
            setUploading(true)
            const result: any = await uploader.upload()
            setUploading(false)
            yachtsList.setYachtPhoto(result.url)
        } catch (err: any) {
            console.error(`[Uploader] Error uploading file: ${err.message}`)
            setUploading(false)
        }
    }, [setUploading, yachtsList])

    const replaceFileList = useCallback(async (file: File) => {
        const data = await getBase64(file)
        setFileList([{ file, data }])
        await upload([file])
    }, [getBase64, upload])

    const uploader = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        disabled: uploading,
        fileList,
        beforeUpload: (file: File) => {
            replaceFileList(file)
            return false
        }
    }
    const onOk = useCallback(() => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                console.log(values);
                values.yacht_photo = yachtsList.newYachtPhoto
                yachtsList.createYacht(values);
                setyachtFormVisible(false);
            })

            .catch((info) => {
                console.log("Validate Failed:", info);
            })
    }, [form, setyachtFormVisible, yachtsList])

    const onCancel = useCallback(() => {
        setyachtFormVisible(false);
        form.resetFields();
    }, [form, setyachtFormVisible])

    return (
        <Modal
            title="Add yacht"
            open={yachtFormVisible}
            onOk={onOk}
            onCancel={onCancel}>
            <Form
                name="addYacht"
                form={form}
                initialValues={{
                    modifier: "public",
                    state: "Open",
                    deadline: moment(),
                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please put in a first name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Blackbox ID"
                    name="blackboxId"
                    rules={[{ required: true, message: 'Please input a last name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Foto van jacht"
                    name="image"
                    rules={[{ required: true, message: 'Please upload a photo' }]}
                >
                    <Upload {...uploader}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default observer(YachtForm)

//Halloo
