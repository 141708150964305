import { useAuthenticator } from "@aws-amplify/ui-react";
import Login from "./Login";
import Fleet from "./Fleet";
import React from "react";
import 'antd/dist/reset.css';

const Home: React.FC = () => {
    const { route } = useAuthenticator(context => [context.route]);
    return route === 'authenticated' ? <Fleet /> : <Login />
}

export default Home