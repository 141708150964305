import { types, flow } from "mobx-state-tree";
import API from "../lib/api";
import { API_ENDPOINT, fetchJson } from "../lib/fetch";

const Maybe = (type: any) => types.maybe(types.maybeNull(type))

export const User = types
  .model("User", {
    id: types.identifier,
    key: types.number,
    name: types.string,
    email: types.string,
    phone_number: types.string,
    role: types.maybe(types.string),
    yachtsUser: types.maybe(types.array(types.string))
  })

export const Position = types.model('Position', {
  latitude: types.number,
  longitude: types.number,
})

export const YachtMetrics = types.model('YachtMetrics', {
  latitude: Maybe(types.number),
  longitude: Maybe(types.number),
  batterySOC: Maybe(types.number),
  fuel: Maybe(types.number),
  range: Maybe(types.number),
  water: Maybe(types.number),
})

export const YachtStatusText = types.model('YachtStatusText', {
  colour: Maybe(types.string),
  icon: Maybe(types.string),
  text: types.string,
})

export const YachtStatus = types.model('YachtStatus', {
  image: Maybe(types.string),
  location: Position,
  status: YachtStatusText,
  metrics: YachtMetrics,
  name: types.string,
})

export const UsersList = types
  .model("UsersList", {
    state: types.optional(types.enumeration(['PENDING', 'FETCHING', 'FETCHED', 'ERRORED']), 'PENDING'),
    users: types.array(User),
    currentUser: types.maybe(types.reference(User)),
    yachtStatus: Maybe(YachtStatus),
  })
  .views(self => ({
    get currentUserIsSet() {
      return self.currentUser !== undefined
    }
  }))
  .actions((self) => {
    const actions: any = {
      setUsers(users: any) {
        self.users = users
      },
      loadUsers: flow(function* loadUsers() { // The action needs to be marked as generator, by postfixing the function keyword with a * and a name (which will be used by middleware), and wrapping it with flow
        if (self.state === 'FETCHING') {
          return
        }
        try {
          self.state = 'FETCHING'
          const results = yield fetchJson(`${API_ENDPOINT}/api/compat/user`) // The action can be paused by using a yield statement. Yield always needs to return a Promise
          for (const element of results) {
            for (let j = 0; j < element.yachts.length; j++) {
              element.yachtsUser = []
              element.yachtsUser.push(element.yachts[j].name)
            }
          }
          for (let i = 0; i < results.length; i++) {
            results[i]["key"] = i
          }
          const items = results.map((item: any) => User.create(item))
          actions.setUsers(items)
          self.state = 'FETCHED'
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to load users ", err)
        }
      }),
      updateUser: flow(function* updateUser(id, updateData) {
        try {
          yield actions.loadUsers();
          const item: any = self.users.find((user) => (user.id === id))

          if (!item) {
            throw new Error(`Item ${id} doesnt exist`)
          }
          if (item.updating) {
            return
          }
          yield fetchJson(`${API_ENDPOINT}/api/compat/user/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({
              ...updateData
            })
          })
          yield actions.loadUsers();
        } catch (err) {
          console.error("Failed to update user ", err)
        }
      }),
      createUser: flow(function* createUser(data) {
        try {
          yield fetchJson(`${API_ENDPOINT}/api/compat/user`, {
            method: 'POST',
            body: JSON.stringify(
              data
            )
          })
          yield actions.loadUsers();
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to create user ", err)
        }
      }),
      destroyUser: flow(function* destroyUser(id) {
        try {
          yield fetchJson(`${API_ENDPOINT}/api/compat/user/${id}`, {
            method: 'DELETE',
          })
          yield actions.loadUsers();
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to delete user", err)
        }
      }),
      getYachtStatus: flow(function * getYachtStatus(email: string) {
        try {
          const status = yield API.yachts.status(email);
          self.yachtStatus = YachtStatus.create(status);
        } catch (err) {
          console.error("Failed load yacht status", err)
        }
      }),
      setCurrentUser(email: string) {
        actions.getYachtStatus(email)

        for (let i = 0; i < self.users.length; i++) {
          if (email === self.users[i].email) {
            self.currentUser = self.users[i]
          }
        }
      }
    }
    return actions
  })
