import { flow, Instance, types } from "mobx-state-tree";
import { API } from "../lib/api";
import { EventInput } from "../lib/types";

export const Event: any = types
  .model("Event", {
    id: types.string,
    createdAt: types.number,
    updatedAt: types.number,
    name: types.string,
    icon: types.optional(types.string, 'info'),
    level: types.optional(types.string, 'normal'),
    yacht: types.optional(types.string, ''),
  })

export const EventList = types
  .model("EventList", {
    state: types.optional(types.enumeration(['PENDING', 'FETCHING', 'FETCHED', 'ERRORED']), 'PENDING'),
    events: types.array(Event),
  })
  .actions((self) => {
    const actions: any = {
      setEvents(events: any[]) {
        self.events.replace([])
        self.events.replace(events)
      },

      loadEvents: flow(function* loadEvents() {
        if (self.state === 'FETCHING') {
          return
        }

        try {
          self.state = 'FETCHING'
          const { listEvents } = yield API.events.list()
          console.log('[listEvents]', listEvents)
          const items = listEvents.map((item: any) => Event.create(item))
          actions.setEvents(items)
          self.state = 'FETCHED'
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to load events ", err)
        }
      }),

      createEvent: flow(function* createEvent(data: EventInput) {
        try {
          yield API.events.create(data);
          yield actions.loadEvents();
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to create event ", err)
        }
      }),
      
      destroyEvent: flow(function* destroyEvent(id) {
        try {
          yield API.events.destroy(id);
          yield actions.loadEvents();
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to delete event", err)
        }
      }),
    }
    return actions
  })

export interface EventModel extends Instance<typeof Event> {}
export interface EventListModel extends Instance<typeof EventList> {}
