import { types, flow } from "mobx-state-tree"
import { addYacht, editYacht, deleteYacht } from "../lib/APIcalls"
import { fetchJson } from "../lib/fetch"

export const Yacht = types
  .model("Yacht", {
    id: types.string,
    key: types.number,
    name: types.string,
    blackboxId: types.string,
    image: types.string
  })

export const YachtsList = types
  .model("YachtsList", {
    state: types.optional(types.enumeration(['PENDING', 'FETCHING', 'FETCHED', 'ERRORED']), 'PENDING'),
    yachts: types.array(Yacht),
    currentPicture: types.maybe(types.string),
    newYachtPhoto: '',
  })
  .actions((self) => {
    const actions: any = {
      loadYachts: flow(function* loadYachts() {
        if (self.state === 'FETCHING') {
          return
        }
        try {
          self.state = "FETCHING"
          const results = yield fetchJson('https://api-staging.marinminds.cloud/api/compat/yacht')
          for (let i = 0; i < results.results.length; i++) {
            results.results[i]["key"] = i
          }
          const items = results.results.map((item: any) => Yacht.create(item))
          self.yachts.replace(items)
          self.state = 'FETCHED'
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to load yachts ", err)
        }
      }),
      createYacht: flow(function* createYacht(data) {
        try {
          yield addYacht(data);
          yield actions.loadYachts()
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to create yacht ", err)
        }
      }),
      updateYacht: flow(function* updateYacht(id, updateData) {
        try {
          yield actions.loadYachts();
          const item: any = self.yachts.find((newyacht) => (newyacht.id === id))

          if (!item) {
            throw new Error(`Item ${id} doesnt exist`)
          }
          if (item.updating) {
            return
          }
          yield editYacht(id, updateData)
          yield actions.loadYachts();
        } catch (err) {
          console.error("Failed to update yacht ", err)
        }
      }),
      destroyYacht: flow(function* destroyYacht(id) {
        try {
          yield deleteYacht(id);
          yield actions.loadYachts();
        } catch (err) {
          self.state = 'ERRORED'
          console.error("Failed to delete yacht", err)
        }
      }),
      setCurrentPicture(picture: any) {
        self.currentPicture = picture
      },
      setYachtPhoto(value: string) {
        self.newYachtPhoto = value
      }
    }
    return actions
  })

