import { Button, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { useStore } from "../models/Rootstore";
import { useNavigate, useParams } from "react-router-dom";
import UserForm from "./UserForm";
import UserTable from "./UserTable";
import YachtForm from "./YachtForm";
import YachtTable from "./YachtTable";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { Content } from "antd/es/layout/layout";

const Admin: React.FC = () => {
    const navigate = useNavigate()
    const { activepanel } = useParams()
    const { setuserFormVisible, setyachtFormVisible } = useStore()


    const showForm = useCallback(() => {
        if (activepanel === "Users") {
            setuserFormVisible(true);
        }
        else if (activepanel === "Yachts") {
            setyachtFormVisible(true);
        }
    }, [activepanel, setuserFormVisible, setyachtFormVisible])

    const tabbarButtons = {
        right: (
            <Button danger className="tabbutton" onClick={showForm}>
                + Add {activepanel}
            </Button>
        )
    }

    const onChange = useCallback((key: string) => {
        navigate(`/admin/${key}`)
    }, [navigate])

    return (
        <Content style={{ marginLeft: 20, marginTop: 20, overflow: 'initial' }} >
            <h2>Admin</h2>
            <Tabs className="tab" defaultActiveKey="Users" onChange={onChange} tabBarExtraContent={tabbarButtons}>
                <TabPane className="userTab" tab="Users" key="Users">
                    <UserForm></UserForm>
                    <UserTable></UserTable>
                </TabPane>
                <TabPane tab="Yachts" key="Yachts">
                    <YachtForm></YachtForm>
                    <YachtTable></YachtTable>
                </TabPane>
            </Tabs>
        </Content>
    )
}

export default observer(Admin)