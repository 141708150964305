import { useStore } from "../models/Rootstore";
import React, { useCallback, useEffect, useState } from 'react';
import '../style/stylesheet.css'
import '../style/stylesheetFleet.css'
import { Content } from "antd/es/layout/layout";
import { observer } from "mobx-react-lite";
import { Progress } from 'antd';
import { translations } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css'
import { I18n } from 'aws-amplify';

I18n.putVocabularies(translations);
I18n.setLanguage('en');

I18n.putVocabulariesForLanguage('en', {
    'Sign in': 'Login',
    'Email': 'Email address',
    'Password': 'Password',
    'Forgot your password?': 'Forgot password?'
});


export const Fleet: React.FC = () => {
    const { usersList, yachtsList } = useStore()
    const state = usersList.state
    const currentUserIsSet = usersList.currentUserIsSet
    const [fleetList, setFleetList] = useState<any[]>([]);

    const setfleetListItem = useCallback(() => {
        if (!usersList.currentUser || !Array.isArray(usersList.currentUser)) {
            return
        }

        for (let i = 0; i < usersList.currentUser.yachtsUser.length; i++) {
            for (let j = 0; j < yachtsList.yachts.length; j++)
                if (yachtsList.yachts[j].name === usersList.currentUser.yachtsUser[i]) {
                    const fleetListItem: { yachtName: any; key: any; yachtPicture: any }[] = []
                    fleetListItem.push({ yachtName: (usersList.currentUser.yachtsUser[i]), key: (usersList.currentUser.yachtsUser[i]), yachtPicture: (yachtsList.yachts[j].image) })
                    setFleetList(fleetListItem)
                }
        }
    }, [usersList.currentUser, yachtsList.yachts])
    useEffect(() => {
        if (fleetList.length === 0 && state === "FETCHED" && currentUserIsSet) {
            setfleetListItem()
            console.log("fleetListItem: " + fleetList)
        }
    }, [currentUserIsSet, fleetList, setfleetListItem, state])


    const fleetListComponent = fleetList.map((item) => //todo: make functional component of this?
        <div className="gridcontainer-fleet" key={item.yachtName}>
            <div className="griditem-fleet1">
                <div className="gridcontainer-fleet1">
                    <div className="griditem-fleet1-1">
                        <img alt="" width="200px" src={item.yachtPicture}></img>
                    </div>
                    <div className="griditem-fleet1-2-1">
                        {item.yachtName}
                    </div>
                    <div className="griditem-fleet1-2-2">

                    </div>
                    <div className="griditem-fleet1-2-3">
                        moored @ Workum
                    </div>
                    <div className="griditem-fleet1-2-4">
                        connected (31d)
                    </div>
                    <div className="griditem-fleet1-3-1"><Progress type="circle" width={45} percent={80} format={() => '8'} /></div>
                    <div className="griditem-fleet1-3-2"><Progress type="circle" width={45} percent={90} format={() => '9'} /></div>
                    <div className="griditem-fleet1-3-3"><Progress type="circle" width={45} percent={70} format={() => '7'} /></div>
                    <div className="griditem-fleet1-3-4"><Progress type="circle" width={45} percent={90} format={() => '9'} /></div>
                    <div className="griditem-fleet1-3-5">health</div>
                    <div className="griditem-fleet1-3-6">sustainability</div>
                    <div className="griditem-fleet1-3-7">safety</div>
                    <div className="griditem-fleet1-3-8">satisfaction</div>
                </div>
            </div>
        </div>
    );
    if (currentUserIsSet) {
        return (
            <Content style={{ marginLeft: 20, marginTop: 20, overflow: 'initial' }} >
                <h2>Fleet of {usersList.currentUser.name}</h2>
                <div className="divFleetList">
                    {fleetListComponent}
                </div>
            </Content>
        )
    }
    else return (<div>Loading...</div>)
}

export default observer(Fleet)