import { useStore } from "../../models/Rootstore"
import { Row, Col, Table, Modal } from 'antd'
import { observer } from "mobx-react-lite"
import React, { useCallback, useEffect, useState } from 'react'
import { EquipmentModel } from "../../models/Equipment";
import { CreateEquipment } from "./CreateEquipment";

import './EquipmentTable.css';
import { DeleteOutlined } from "@ant-design/icons";
import ManualEvents from "../ManualEvents";

const createColumns = (destroyEquipment: (id: string) => () => any, kind: string = 'EQUIPMENT') => {
  const cols = [
    {
      dataIndex: 'name',
      title: '',
      render: (name: string, record: EquipmentModel) => {
        return (
          <div className='name-column'>
            <span className='name-column--name'>{name}</span>
            <span className='name-column--delete-icon' onClick={destroyEquipment(record.id)}><DeleteOutlined /></span>
          </div>
        )
      }
    },
    {
      dataIndex: 'make',
      title: 'make',
      width: 120,
    },
    {
      dataIndex: 'model',
      title: 'model',
      width: 120,
    },
    {
      dataIndex: 'location',
      title: 'location',
      width: 120,
    },
    {
      dataIndex: 'supplier',
      title: 'supplier',
      width: 120,
    },
    {
      dataIndex: 'serviceSupplier',
      title: 'service supplier',
      width: 150,
    },
    {
      dataIndex: 'partNumber',
      title: 'part number',
      width: 120,
    },
  ]

  if (kind === 'PLANNED_MAINTENANCE') {
    return [ cols[0] ]
  }

  return cols
}

export interface EquipmentTableTableProps {
  state: string
  equipment: EquipmentModel[]
  isChild?: boolean
  parentChildren?: number
  parent?: string
  destroyEquipment: (id: string) => () => any
  kind: string
}

const renderRowChildren = (kind: string, destroyEquipment: (id: string) => any, list: EquipmentModel[], EquipmentTableTable: React.FC<EquipmentTableTableProps>) => (record: EquipmentModel, index: number) => {
  const equipment: EquipmentModel[] = list.slice().filter((item: EquipmentModel) => (
    item.parent === record.id
  ));

  return (
    <div style={{ paddingLeft: '42px' }}>
      {equipment.length > 0 && (
        <EquipmentTableTable
          state='FETCHED'
          equipment={list}
          isChild={true}
          parentChildren={equipment.length}
          parent={record.id}
          destroyEquipment={destroyEquipment}
          kind={kind}
        />
      )}
      <CreateEquipment parent={record} kind={kind} />
    </div>
  )
}

const EquipmentTableTable: React.FC<EquipmentTableTableProps> = ({ kind, equipment, isChild, parentChildren, parent, destroyEquipment }) => {
  const renderChildren = !isChild || (typeof parentChildren === 'number' && parentChildren > 0)
  const list = equipment.slice().filter((item: EquipmentModel) => (
    !parent ? (
      typeof item.parent !== 'string' ||
      item.parent.trim() === ''
    ) : (
      item.parent === parent
    )
  ))

  return (
    <div style={!isChild && kind === 'PLANNED_MAINTENANCE' ? { width: '40%' } : {}}>
      <Table
        pagination={false}
        dataSource={list}
        columns={createColumns(destroyEquipment, kind)}
        size='small'
        rowKey='id'
        scroll={{ x: true }}
        expandRowByClick={renderChildren}
        expandedRowRender={renderChildren ? renderRowChildren(kind, destroyEquipment, equipment, EquipmentTableTable) : undefined}
        showHeader={!isChild}
      />
    </div>
  )
}

const EquipmentTable: React.FC<{ title: string }> = ({ title }) => {
  const { equipmentList } = useStore()
  const [ modelState, replaceModelState ] = useState('PENDING') // Hack: required because something weird's going on in the data layer
  const [ equipmentListLength, replaceEquipmentListLength ] = useState(-1) // Hack: required because something weird's going on in the data layer

  useEffect(() => {
    if (modelState !== equipmentList.state) {
      replaceModelState(equipmentList.state)
    }

    if (equipmentList.equipment.length !== equipmentListLength) {
      replaceEquipmentListLength(equipmentList.equipment.length)
    }
    
    equipmentList.loadEquipment();
  }, [ equipmentList, modelState, equipmentListLength, replaceModelState, replaceEquipmentListLength ])

  const destroyEquipment = useCallback((id: string) => () => {
    Modal.confirm({
      title: 'Zeker?',
      content: 'Deze actie kan niet ongedaan worden gemaakt.',
      onCancel: () => false,
      onOk: () => {
        equipmentList.destroyEquipment(id)
        return false
      }
    })
  }, [ equipmentList ])

  const kind = title === 'Planned Maintenance' ? 'PLANNED_MAINTENANCE' : 'EQUIPMENT'

  return (
    <Row>
      <Col span={6}>
        <ManualEvents />
      </Col>

      <Col span={18}>
        <section className='equipmentTable--Outer'>
          <div className='equipmentTable--Inner'>
            <h1>{title || 'Equipment'}</h1>
            <EquipmentTableTable
              state={equipmentList.state}
              equipment={equipmentList.equipment.slice()}
              destroyEquipment={destroyEquipment}
              kind={kind}
            />
            
            {kind !== 'PLANNED_MAINTENANCE' && <CreateEquipment kind={kind} />}
          </div>
        </section>
      </Col>
    </Row>
  )
}

export default observer(EquipmentTable)