import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Admin from './Admin';
import Fleet from './Fleet';
import Dashboard from './Dashboard';
import Home from './Home';
import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import SideMenu from './SideMenu';
import { useStore } from '../models/Rootstore';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Router: React.FC = () => {
    const { usersList } = useStore()
    const { user } = useAuthenticator((context) => [context.route])
    const state = usersList.state
    const currentUserIsSet = usersList.currentUserIsSet
    let email = String(user?.attributes?.email)
    useEffect(() => {
        if (state === "FETCHED" && !currentUserIsSet) {
            usersList.setCurrentUser(email)
        }
    }, [email, usersList, usersList.users, state, currentUserIsSet])
    return (
        <Layout hasSider style={{height: "100vh", overflow:"hidden"}}>
            <SideMenu />
            <Layout className="site-layout" style={{marginLeft: 10, overflowY:"scroll"}}>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/dashboard/' element={<Dashboard />} />
                    <Route path='/dashboard/:yachtname' element={<Dashboard />} />
                    <Route path='/dashboard/:yachtname/:activeKey' element={<Dashboard />} />
                    <Route path='/dashboard/:yachtname/:activeKey/:category' element={<Dashboard />} />
                    <Route path='/fleet/:userFleet' element={<Fleet />} />
                    <Route path='/admin' element={<Admin />} />
                    <Route path='/admin/:activepanel' element={<Admin />} />
                </Routes>
            </Layout>
        </Layout>
    )
}

export default observer(Router)