import { request } from 'graphql-request'

export const API_ENDPOINT = `https://api-staging.marinminds.cloud`
// export const API_ENDPOINT = 'http://localhost:1601'
export const GQL_ENDPOINT = `${API_ENDPOINT}/graphql`


// eslint-disable-next-line
function createError (response: any) {
  // eslint-disable-next-line
  const fetchError = new Error(response.statusText) as any
  fetchError.response = response
  fetchError.code = response.status
  return fetchError
}

export const clone = (mixed: any) => JSON.parse(JSON.stringify(mixed))
// eslint-disable-next-line
export async function fetchGQL(query: any) {
  return await request(GQL_ENDPOINT, query)
}
  
export async function fetchJson(url: string, init: Partial<RequestInit> = {}) {
  const baseInit: RequestInit = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  if (init && (init.method === 'POST' || init.method === 'PUT' || init.method === 'PATCH')) {
    baseInit.mode = 'cors'
  }

  const uri = url.toLowerCase().startsWith('http') ? url : `${API_ENDPOINT}${url.startsWith('/') ? '' : '/'}${url}`
  const opts = { ...baseInit, ...(init || {}) }
  const response = await fetch(uri, opts)

  if (!response.ok) {
    console.error(`${opts.method} ${uri} not OK: ${response.status}`)
    throw createError(response)
  }

  // Assuming a JSON request
  const data = await response.json()

  if (response.ok) {
    return data
  }

  throw createError(response)
}