import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'
import { useStore } from '../../models/Rootstore'
import { Row, Col, Modal } from 'antd'
import { ExclamationCircleOutlined, InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined, WarningOutlined } from '@ant-design/icons'

import './ManualEvents.css'
import { EventModel } from '../../models/Events'
import { AddManualEvent } from './AddManualEvent'

export interface ManualEventProps {
  name: string
  createdAt: number
  level?: string
  icon?: string
  destroy: () => any
}

export const getIcon = (icon?: string) => {
  switch (icon) {
    case 'warning':
      return <WarningOutlined />

    case 'danger':
      return <ExclamationCircleOutlined />
      
    default: 
      return <InfoCircleOutlined />
  }
}

export const getOffset = (createdAt: number) => {
  const now = Date.now()
  const secs = (now - createdAt) / 1000
  
  if (secs <= 60) {
    return `+${Math.round(secs)}s`
  }

  const mins = secs / 60;

  if (mins < 60) {
    return `+${Math.round(mins)}m`
  }

  return ''
}

export const ManualEvent: React.FC<ManualEventProps> = ({ name, level, icon, createdAt, destroy }) => {
  return (
    <section className={['manual-events--event', `manual-events--event--${level}`].join(' ')}>
      <div className='manual-events--event--inner'>
        <Row>
          <Col span={3}>{getIcon(icon)}</Col>
          <Col span={18}>{name}</Col>
          <Col span={3}>
            <div className='manual-events--event--time' onClick={destroy}>
              {getOffset(createdAt)}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export const ManualEvents: React.FC = observer(() => {
  const [ isEditing, setEditing ] = useState(false)
  const { eventList } = useStore()
  const { events } = eventList;

  const toggleAddEvent = useCallback(() => setEditing(!isEditing), [ isEditing, setEditing ])
  const destroyManualEvent = useCallback((id: string) => () => Modal.confirm({
    title: 'Weet je het zeker?',
    content: 'Deze actie kan niet ongedaan gemaakt worden',
    onCancel: () => false,
    onOk: () => {
      eventList.destroyEvent(id)
      return false
    }
  }), [ eventList ])

  return (
    <section className='manual-events-table'>
      <div className='manual-events-table--outer'>
        <div className='manual-events-table--inner'>
          <Row>
            <Col span={20}>
              <h1>Timeline</h1>
            </Col>
            <Col span={4}>
              <div className='manual-events-table--add-event' onClick={toggleAddEvent}>
                {isEditing ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
              </div>
            </Col>
          </Row>

          {isEditing && <AddManualEvent />}
          
          <div className='manual-events-table--list'>
            {events.slice().sort((a: EventModel, b: EventModel) => (b.createdAt - a.createdAt)).map(({ id, name, level, icon, createdAt }: EventModel) => (
              <ManualEvent
                key={id}
                name={name}
                level={level}
                icon={icon}
                createdAt={createdAt}
                destroy={destroyManualEvent(id)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
})

export default(ManualEvents)
