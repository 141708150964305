import { Form, Input, Modal, Select } from 'antd'
import { useStore } from '../models/Rootstore'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'

const passwordValidator = require('password-validator')

const UserForm: React.FC = () => {
    const { Option } = Select
    const [form] = Form.useForm()
    const { setuserFormVisible, userFormVisible, usersList, yachtsList } = useStore()
    const schema = new passwordValidator();

    schema
        .is()
        .min(8)
        .has()
        .uppercase()
        .has()
        .lowercase()
        .has()
        .digits()
        .has()
        .symbols();

    const onOk = useCallback(() => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields()
                console.log(values)
                const date = new Date();
                values.createdAt = date.toISOString()
                values.active = true
                values.status = "UNCONFIRMED"
                for (const element of yachtsList.yachts) {
                    if (values.yachts === element.name) {
                        values.yachts = element
                    }
                }
                console.log(values)
                usersList.createUser(values)
                setuserFormVisible(false)
            })
            .catch((info) => {
                console.log("Validate Failed:", info)
            })
    }, [form, setuserFormVisible, usersList, yachtsList.yachts])

    const onCancel = useCallback(() => {
        setuserFormVisible(false)
        form.resetFields()
    }, [form, setuserFormVisible])

    return (
        <Modal
            title="Add user"
            open={userFormVisible}
            onOk={onOk}
            onCancel={onCancel}>
            <Form
                name="addUser"
                form={form}
                initialValues={{
                    modifier: "public",
                    state: "Open",
                    deadline: moment(),
                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please put in a first name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input an emailaddress!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Phonenumber"
                    name="phone_number"
                    rules={[{ required: true, message: 'Please input a phonenumber!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="role"
                    name="role"
                    rules={[{ required: true, message: 'Please choose a role!' }]}
                >
                    <Select>
                        <Option value="manager">manager</Option>
                        <Option value="yo">yo</Option>
                        <Option value="sym">sym</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Which yachts can this user have acces to"
                    name="yachts"
                    rules={[{ required: true, message: 'Please choose a status!' }]}
                >
                    <Select mode="multiple">

                        {useMemo(() => yachtsList.yachts.map((item: any, index: any) => (
                            <Option value={item.name} key={item[index]}>
                                {item.name}
                            </Option>
                        )), [Option, yachtsList.yachts])}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default observer(UserForm)
