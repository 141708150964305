import React, { useState, useCallback } from 'react'
import { observer } from "mobx-react-lite"
import { EquipmentModel } from '../../models/Equipment'
import { Input, Button } from 'antd'

import './CreateEquipment.css'
import { PlusOutlined } from '@ant-design/icons'
import { useStore } from '../../models/Rootstore'


export interface CreateEquipmentProps {
  parent?: EquipmentModel
  kind?: string
}

export const CreateEquipment: React.FC<CreateEquipmentProps> = observer(({ parent, kind }) => {
  const { equipmentList } = useStore()
  
  const [ name, replaceName ] = useState('')
  const [ make, replaceMake ] = useState('')
  const [ model, replaceModel ] = useState('')
  const [ location, replaceLocation ] = useState('')
  const [ supplier, replaceSupplier ] = useState('')
  const [ serviceSupplier, replaceServiceSupplier ] = useState('')
  const [ partNo, replacePartNo ] = useState('')
  const [ isValid, setIsValid ] = useState(false)
  const [ isCreating, setIsCreating ] = useState(false)

  const clear = useCallback(() => {
    replaceName('')
    replaceMake('')
    replaceModel('')
    replaceLocation('')
    replaceSupplier('')
    replaceServiceSupplier('')
    replacePartNo('')
    setIsValid(false)
    setIsCreating(false)
  }, [
    replaceName,
    replaceMake,
    replaceModel,
    replaceLocation,
    replaceSupplier,
    replaceServiceSupplier,
    replacePartNo,
    setIsValid,
    setIsCreating,
  ])

  const checkIsValid = useCallback(() => {
    if (name.trim() === '') {
      return setIsValid(false)
    }

    return setIsValid(true)
  }, [ name, setIsValid ])

  const handleChange = useCallback((replacer: any) => ({ target: { value } }: any) => {
    replacer(value)
    checkIsValid()
  }, [ checkIsValid ])

  const createEquipment = useCallback(() => {
    const input: any = {
      name,
      make,
      model,
      location,
      supplier,
      serviceSupplier,
      partNo,
      parent: null
    }
    
    if (parent && typeof parent.id === 'string') {
      input.parent = parent.id
    }

    setIsCreating(true)
    equipmentList.createEquipment(input)
    clear()
  }, [
    equipmentList,
    setIsCreating,
    clear,
    parent,
    name,
    make,
    model,
    location,
    supplier,
    serviceSupplier,
    partNo,
  ])

  return (
    <div className='create-equipment--outer'>
      <div className='create-equipment--inner'> 
        <div className='create-equipment--row'>
          <div className='create-equipment--col create-equipment--col-spacer'>
            <Button size='small' type='primary' disabled={!isValid || isCreating} onClick={createEquipment}>
              <PlusOutlined />
            </Button>
          </div>
          <div className='create-equipment--col create-equipment--col-grow'>
            <Input disabled={isCreating} placeholder='Name...' value={name} onChange={handleChange(replaceName)} onBlur={checkIsValid} />
          </div>
          
          {kind !== 'PLANNED_MAINTENANCE' && (
            <>
              <div className='create-equipment--col' style={{ width: '120px' }}>
                <Input disabled={isCreating} placeholder='Make...' value={make} onChange={handleChange(replaceMake)} onBlur={checkIsValid} />
              </div>
              <div className='create-equipment--col' style={{ width: '120px' }}>
                <Input disabled={isCreating} placeholder='Model...' value={model} onChange={handleChange(replaceModel)} onBlur={checkIsValid} />
              </div>
              <div className='create-equipment--col' style={{ width: '120px' }}>
                <Input disabled={isCreating} placeholder='Location...' value={location} onChange={handleChange(replaceLocation)} onBlur={checkIsValid} />
              </div>
              <div className='create-equipment--col' style={{ width: '120px' }}>
                <Input disabled={isCreating} placeholder='Supplier...' value={supplier} onChange={handleChange(replaceSupplier)} onBlur={checkIsValid} />
              </div>
              <div className='create-equipment--col' style={{ width: '150px' }}>
                <Input disabled={isCreating} placeholder='Service supplier...' value={serviceSupplier} onChange={handleChange(replaceServiceSupplier)} onBlur={checkIsValid} />
              </div>
              <div className='create-equipment--col' style={{ width: '120px' }}>
                <Input disabled={isCreating} placeholder='Part number...' value={partNo} onChange={handleChange(replacePartNo)} onBlur={checkIsValid} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
})
