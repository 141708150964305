import { useStore } from "../models/Rootstore";
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import '../style/stylesheet.css'
import { observer } from "mobx-react-lite";
import { Layout, Menu } from 'antd';
import { useAuthenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';

const { Sider } = Layout;

const SideMenu: React.FC = () => {
    const navigate = useNavigate()
    const { usersList } = useStore()
    const { user, signOut } = useAuthenticator((context) => [context.route])
    const [yachtsLabeltjes, setYachtsLabeltjes] = useState<any[]>([]);
    let email = String(user?.attributes?.email)

    const onClickSpecificYacht = useCallback((e: any) => {
        navigate(`/dashboard/${e.key}`);
    }, [navigate])

    const onClickFleet = useCallback(() => {
        navigate(`/fleet/${usersList.currentUser.name}`)
    }, [navigate, usersList])

    const setYachtsLabels = useCallback(() => {
      if (!usersList.currentUser || !Array.isArray(usersList.currentUser.yachtsUser)) {
        return
      }

      setYachtsLabeltjes([{ onClick: onClickFleet, label: "Fleet", key: "ShowFleet" }])
      for (const user of usersList.currentUser.yachtsUser) {
        setYachtsLabeltjes(prev => [...prev, {
          onClick: onClickSpecificYacht,
          label: (user),
          key: (user)
        }])
      }
    }, [onClickFleet, onClickSpecificYacht, usersList.currentUser])

    useEffect(() => {
        if (usersList.currentUser && yachtsLabeltjes.length === 0) {
            setYachtsLabels()
        }
    }, [setYachtsLabels, usersList.currentUser, yachtsLabeltjes.length])

    const onClickAdmin = useCallback(() => {
        navigate('/admin')
    }, [navigate])

    const clickSignOut = useCallback(() => {
        navigate('/')
        signOut()
    }, [navigate, signOut])

    const managers = ['manager', 'superuser']


    return (
        <Sider width="max-content"
            style={{
                overflow: 'auto',
                height: '100vh',
                position: "sticky",
                left: 0,
                top: 0,
                bottom: 0,
                zIndex: 1,
                backgroundColor: "black"
            }}>

            <img alt='' className="logo" width="40px" src={require("../assets/m-logo.png")} />
            <Menu

                mode="vertical"
                theme="dark"
                items={[
                    {
                        label: <img className="yachtImage" width="40px" src={require("../assets/yacht-icon.png")} alt="yachts" />,
                        key: 'yachtsubmenu',
                        children: yachtsLabeltjes //ToD0: give children css styling
                    }
                ]}
            />

            {usersList.currentUser ? (
                <Menu
                    title="email"
                    mode="vertical"
                    theme="dark"
                    style={{
                        position: 'absolute',
                        bottom: 10
                    }}
                    items={[
                        ...(managers.includes(usersList.currentUser.role) ? [{ label: <img onClick={onClickAdmin} width="40px" src={require("../assets/admin-icon.png")} alt='' />, key: "admin" }] : []),
                        {
                            label: <img width="40px" src={require("../assets/user-profile-icon.png")} alt='' />, key: 'userMenu', children: [
                                {
                                    label: email,
                                    type: "group",
                                    key: "group1",
                                    children: [
                                        { onClick: clickSignOut, label: 'Log out', key: 'logout' }
                                    ]
                                }
                            ]
                        },
                    ]
                    }
                />
            ) : (
                <div></div>
            )}


        </Sider>

    )
}


export default observer(SideMenu)